import React from 'react';
import { exportToCSV } from 'services/utils/js/exportToCSV';
import { ActionButton } from 'components/Common/ActionButton';
import { TCurrentGood, TGetContractorGoodsResponse, TGetOrderBuyGroupResponse } from 'services/store/interfaces';
import { useTranslation } from 'react-i18next';
import { getOrderStatus } from 'services/utils/js/getOrderStatus';

type TUnionKeys = TGetContractorGoodsResponse | TGetOrderBuyGroupResponse | TCurrentGood;
interface IExportDataProps {
	data: TUnionKeys[];
	filename: string;
	current: string | undefined;
}

const ExportFields: TUnionKeys = {
	OrderBuyGroupId: 'export.OrderBuyGroupId',
	Checkx: 'export.Checkx',
	Datex: 'export.Datex',
	WarehouseId: 'export.WarehouseId',
	LineNumber: 'export.LineNumber',
	GoodsId: 'export.GoodsId',
	GoodsName: 'export.GoodsName',
	Barcode1: 'export.Barcode1',
	QuantityOrder: 'export.QuantityOrder',
	OrderSum: 'export.OrderSum',
	QuantityAdd2: 'export.QuantityAdd2',
	Sum2Add2: 'export.Sum2Add2',
	Firstx: 'export.Firstx',
	Price: 'export.Price',
	Price2: 'export.Price2',
	// ShipmentDateLast: 'export.ShipmentDateLast',
	DepartmentAddress: 'export.DepartmentAddress',
	QuantityInPack: 'export.QuantityInPack',
	QuantityInPallet: 'export.QuantityInPallet',
	GLNWarehouse: 'export.GLNWarehouse',
};

export const getResultPropsTable = (data: TUnionKeys[]) => {
	const { t } = useTranslation();

	const list = data.map((item) => {
		let result = {};
		const keys = Object.keys(ExportFields);

		keys.map((key) => {
			const prop = ExportFields[key as keyof TUnionKeys] as string;

			if (!prop) return;
			result = {
				...result,
				[t(prop)]: key === 'Checkx' ? t(getOrderStatus(item).text) : item[key as keyof TUnionKeys],
			};
		});

		return result;
	});

	return list;
};

export const ExportOrders = ({ data, filename, current }: IExportDataProps) => {
	const { t } = useTranslation();

	const list = getResultPropsTable(data);
	const name = current ? `${t(filename)} №${current}` : t(filename);
	const onClick = () => {
		exportToCSV(list, name, [6, 15], [0, 1, 2, 3, 4, 5, 6, 7, 15, 18]);
	};

	return <ActionButton text={t('export.Export')} type="export" onClick={onClick} />;
};
