import React from 'react';
import styles from './homeBanners.module.scss';
import { EplusBanner } from '../EplusBanner';

export const HomeBanners = () => {
	return (
		<div className={styles.homeBanners}>
			<EplusBanner />
		</div>
	);
};
