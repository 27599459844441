import React from 'react';
import styles from './eplusBanner.module.scss';
import Eplus from 'assets/images/home/eplus.svg';

export const EplusBanner = () => {
	return (
		<div
			className={styles.eplusBanner}
			onClick={(e) => {
				e.preventDefault();
				window.open('https://evroopt.by/app-eplus/', '_blank');
			}}
		>
			<img src={Eplus} alt="" />
		</div>
	);
};
