import React from 'react';
import styles from './withActions.module.scss';
import { ReactComponent as LogosSVG } from 'assets/images/home/evr_logos.svg';
import { ReactComponent as ArrowSVG } from 'assets/images/home/arrow.svg';
import Rec1 from 'assets/images/home/rec1.png';
import Rec2 from 'assets/images/home/rec2.png';
import Rec3 from 'assets/images/home/rec3.png';
import Rec4 from 'assets/images/home/rec4.png';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
export const WithActions = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.banner}>
			<div className={styles.banner_main}>
				<div className={styles.banner_info}>
					<div className={styles.banner_title}>
						{t('home.advertising')}

						<span className={styles.banner_logos}>
							<LogosSVG />
						</span>
					</div>

					<div className={styles.banner_description}>{t('home.help')}</div>

					<div className={styles.banner_actions}>
						<div
							className={classNames(styles.banner_action, styles.banner_more)}
							id="more"
							onClick={(e) => {
								e.preventDefault();
								window.open('https://evroopt.by/wp-content/uploads/about/advertising_Evrotorg_2024.pdf', '_blank');
							}}
						>
							{t('home.more')}
							<div className={styles.banner_action__arrow}>
								<ArrowSVG />
							</div>
						</div>

						<div
							className={classNames(styles.banner_action, styles.banner_consultation)}
							id="consultation"
							onClick={(e) => {
								e.preventDefault();
								window.open('https://evroopt.by/about/razmeshhenie-reklamnoj-informatsii/', '_blank');
							}}
						>
							{t('home.consult')}
							<div className={styles.banner_action__arrow}>
								<ArrowSVG />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.banner_images}>
				<div className={styles.banner_images_row}>
					<img src={Rec1} alt="Rec1" />
					<img src={Rec2} alt="Rec2" />
				</div>

				<div className={styles.banner_images_row}>
					<img src={Rec3} alt="Rec3" />
					<img src={Rec4} alt="Rec4" />
				</div>
			</div>
		</div>
	);
};
