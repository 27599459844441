import React from 'react';
import { HomeText } from './HomeText';
import { HomeDocs } from './HomeDocs';
import { HomeBanners } from './HomeBanners';
import styles from './userHome.module.scss';
import { WithActions } from './WithActions';

export const UserHome = () => {
	return (
		<div className={styles.userHome}>
			<HomeText />

			<div className={styles.userHome__right}>
				<WithActions />

				<div className={styles.userHome__banners}>
					<HomeDocs />
					<HomeBanners />
				</div>
			</div>
		</div>
	);
};
